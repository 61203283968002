import '../components/quantity-selector';
import renderSectionFromResponse from '../utils/render-section-from-response';
import dispatchEvent from '../utils/dispatch-event';
import updateBagCount from '../utils/update-bag-count';

class CartDrawer extends HTMLElement {
  constructor() {
    super();

    this.registerEventListeners();
  }

  renderCart() {
    fetch('/cart?sections=cart-drawer')
      .then(response => response.json())
      .then(response => {
        renderSectionFromResponse(
          '[data-cart-drawer]',
          'cart-drawer',
          response
        );

        setTimeout(() => {
          dispatchEvent('cart:handle-promotions', document);
          dispatchEvent('cart:rerendered', document);
        }, 500);
      });
  }

  registerEventListeners() {
    document.addEventListener('cart:quantity-updated', () => {
      this.renderCart();
      updateBagCount();
    });
  }
}

customElements.define('cart-drawer', CartDrawer);
